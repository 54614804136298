<template>
    <v-main class="mt-4">
        <div class="main-content main-content_small">
            <!-- <span v-if="$route.path !== '/avatars/view'" class="close-button-tab" @click="$router.go(-1)">
                <div class="close-button-tab__close"><v-icon color="var(--surface-0)">mdi-close</v-icon></div>
            </span> -->
            <router-view></router-view>
            <!-- <keep-alive include="PlayerAvatars, EditPlayerAvatars, ViewPlayerAvatars">
              </keep-alive> -->
             
        </div>
    </v-main>
</template>

<script>
    export default {
        name: 'PlayerAvatars',
        data() {
            return {
            }
        },
        async mounted() {
        },
        methods: {
        },
        beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$store.commit('setPageName', 'players_ava')
    });
  },
    }
</script>

<style lang="scss" scoped>
</style>